import React, { Component } from "react";
import Select from "react-select";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Map from "../Map";
import LoadsAPI from "../../../api/loadsAPI";
import TransportersAPI from "../../../api/transportersAPI";
import { sortByPriority } from "../../../helpers/sortByPriority";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import "./style.css";
import OpportunitiesAPI from "../../../api/opportunitiesAPI";
import DocumentsApi from "../../../api/documentsApi";
import SubscriptionAPI from "../../../api/subscriptionAPI";
import SubscriptionContext from "../../../components/Layout/SubscriptionContext"
import uuidv4 from "uuid";
import { googleSearch, googleMapSearch, phoneCall, mailTo, googleMapRoute } from '../../../helpers/LinkUtils';
import formatAddress from "../../../utils/AddressParser";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import "./timeline.css";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { formatShortDate, formatInputDate } from '../../../helpers/DateUtils';
import { getPaymentTerm } from '../../../helpers/PaymentUtils';
import { BackgroundCheckStatus, getBackgroundCheckStatus } from '../../../helpers/BackgroundCheck';

const DEFAULT_DISTANCE_STEP = 10;
const DEFAULT_PICKUP_DISTANCE = 50;
const DEFAULT_DELIVERY_DISTANCE = 100;
const PAGE_SIZE = 5;

const SELECT_DRIVER_HINT = " Select Driver or Type City, State ";

class RowItem extends React.Component {

  constructor(props) {
    super(props)

    // Call the helper function to initialize the state
    const offerValues = calculateOfferValues(props.item);

    this.state = {
      backgroundCheckStatus: this.props.backgroundCheckStatus,
      open: false,
      carData: props.item.carData || [],
      offerData: props.item.offerData || null, 
      commentMode: null,
      priceMode: null,
      priceText: '',
      commentText: '',
      load: props.item,
      subscriptionStatus: '',
      isLoading: props.isLoading,
      ...offerValues
    }
  }

  getRevenue = (load, offer) => {
    if (!load || !offer) {
      return 0;
    }

    const loadHauling = parseFloat(load.hauling);
    const offerHauling = parseFloat(offer.hauling);

    if (isNaN(loadHauling) || isNaN(offerHauling)) {
      return 0;
    }

    return offerHauling - loadHauling;
  }

  onAccept = async (event, errors) => {
    const { offerData, load } = this.state;

    this.setState({offerAccepted: true});

    const offerResponse = await OpportunitiesAPI.confirmOffer(offerData.id, load.id, null).catch((e) => {
      if (e.message === "Request failed with status code 403") {
        this.setState({submitOperation: "Offer Already Confirmed", allowSubmit: false, needConfirmation: false });

        setTimeout(() => {
          this.setState({ submitOperation: "Accepted", allowSubmit: true, offerAccepted: false, needConfirmation: true });
        }, 2000);
      } else {
        this.setState({submitOperation: "Offer Accept Failed", allowSubmit: true});
      }
    });

    if (offerResponse && offerResponse.status === 200 && offerResponse.data) {
      this.setState({submitOperation: "Offer Accepted", allowSubmit: false, offerAccepted: true, needConfirmation: false });

      setTimeout(() => {
        this.setState({allowSubmit: false, cancelOffer: false, termsRequired: this.state.termsRequired});
      }, 2000);
    } else {
      this.setState({submitOperation: "Offer Accept Failed", allowSubmit: false});
    }
  }

  onDecline = async (event, errors) => {
    const { offerData } = this.state;

    this.setState({offerDeclined: true});

    const offerResponse = await OpportunitiesAPI.rejectOffer(offerData.id).catch((e) => {
      if (e.message === "Request failed with status code 403") {
        this.setState({submitOperation: "Offer Already Declined", allowSubmit: false, needConfirmation: false });

        setTimeout(() => {
          this.setState({ submitOperation: "Declined", allowSubmit: true, cancelOffer: false, needConfirmation: true });
        }, 2000);
      } else {
        this.setState({submitOperation: "Offer Decline Failed", allowSubmit: true});
      }
    });

    if (offerResponse && offerResponse.status === 200 && offerResponse.data) {
      this.setState({submitOperation: "Offer Declined", allowSubmit: false, offerDeclined : true, needConfirmation: false });

      setTimeout(() => {
        this.setState({allowSubmit: false, cancelOffer: false, termsRequired: this.state.termsRequired});
      }, 2000);
    } else {
      this.setState({submitOperation: "Offer Decline Failed", allowSubmit: false});
    }
  }

  submit = async (event, errors) => {
    const {pick_up_date, delivery_date, hauling, load, cancelOffer, offerData, needConfirmation} = this.state;

    this.setState({allowSubmit: false});

    if (needConfirmation) {
      return;
    }
    
    if (cancelOffer) {
      const offerResponse = await OpportunitiesAPI.deleteOffer(offerData.id).catch((e) => {
        if (e.message === "Request failed with status code 403") {
          this.setState({submitOperation: "Offer Already Cancelled", allowSubmit: false });

          setTimeout(() => {
            this.setState({ submitOperation: "Send Offer", allowSubmit: true, cancelOffer: false });
          }, 2000);
        } else {
          this.setState({submitOperation: "Offer Cancel Failed", allowSubmit: true});
        }
      });

      if (offerResponse && offerResponse.status === 200 && offerResponse.data) {
        this.setState({submitOperation: "Offer Cancelled", allowSubmit: false });

        setTimeout(() => {
          load.offerData = null;
          const offerValues = calculateOfferValues(load);
          this.setState({load: load, offerData: null, ...offerValues, allowSubmit: true, cancelOffer: false, termsRequired: this.state.termsRequired});
        }, 2000);
      } else {
        this.setState({submitOperation: "Offer Cancel Failed", allowSubmit: true});
      }

      return;
    }

    if (errors.length === 0) {
      try {
        const offerResponse = await OpportunitiesAPI.createOffer({pick_up_date, delivery_date, price: hauling, load_id: load.id}).catch((e) => {
          if (e.message === "Request failed with status code 403") {
            this.setState({submitOperation: "Offer Already Sent", allowSubmit: false });

            setTimeout(() => {
              this.setState({ submitOperation: "Cancel Offer", allowSubmit: true, cancelOffer: true });
            }, 2000);
          } else {
            this.setState({submitOperation: "Offer Send Failed", allowSubmit: true});
          }
        });

        if (offerResponse.status === 200 && offerResponse.data) {
          this.setState({submitOperation: "Offer Sent", allowSubmit: false, cancelOffer: true});

          setTimeout(() => {
            this.setState({ submitOperation: "Cancel Offer", allowSubmit: true, cancelOffer: true });
          }, 2000);

        } else {
          this.setState({submitOperation: "Offer Send Failed", allowSubmit: true});
        }
        return;
      } catch (e) {
        console.error("submitOffer", e);
      }
    }
  }

  handleChange = async (event) => {
    const {target} = event;

    if (target.name === 'img') {
      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          image: reader.result,
          imageFile: file,
          imageName: file.name,
        });
      };
      reader.onerror = () => {
        this.setState({image: null, imageFile: null});
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({[target.name]: ( target.name === "termsRequired" ? target.value === "true" || target.value === true : target.value ), revenue: target.name === "hauling" ? this.getRevenue(this.state.load, { hauling: target.value }) : this.state.revenue})
    }
  }

  pricePerMile = (offerPrice, distance) => {
    let offerPricePerMiles = 0.0;

    if (!isNaN(distance) && !isNaN(offerPrice) && offerPrice !== 0) {
      offerPricePerMiles = ( parseFloat(offerPrice) / parseFloat(distance) ).toFixed(2);
    } else {
      offerPricePerMiles = NaN;
    }

    return offerPricePerMiles;
  }

  render() {
    const { load: item, pick_up_date, delivery_date, revenue } = this.state
    const isOpen = true;

    if (item.origin_data === null) {
      item.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    const originTitle = formatAddress(item.origin_data.address);
    const originName = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? item.origin_data.name || '-' : "Origin";
    const originAddress = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? item.origin_data.address : originTitle;
    const originPhone = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED && item.origin_data.phone;
    const originEmail = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED && item.origin_data.email;

    if (item.destination_data === null) {
      item.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    const destinationTitle = formatAddress(item.destination_data.address);
    const destinationName = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? item.destination_data.name || '-' : "Destination";
    const destinationAddress = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? item.destination_data.address : destinationTitle;
    const destinationPhone = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED && item.destination_data.phone;
    const destinationEmail = this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED && item.destination_data.email;

    const brokerPresent = item.creator_data ? true : false;
    const brokerCompany = brokerPresent ? 
      (item.creator_data.company ? item.creator_data.company : "-")
      : '-';
    const brokerName = brokerPresent ? 
      (item.creator_data.first_name && item.creator_data.last_name ?
      (item.creator_data.first_name + " " + item.creator_data.last_name).trim() : "-")
      : '-';

    const brokerAddress = brokerPresent ? item.creator_data.address : '';
    const brokerPhone = brokerPresent ? item.creator_data.phone : '';
    const brokerEmail = brokerPresent ? item.creator_data.email : '';

    const paymentTerm = getPaymentTerm(item.is_cod);
    const offerPrice = this.state.hauling;
    let offerPricePerMiles = this.pricePerMile(offerPrice, item.miles);

    return (
      <tr className={'tr-additional-info tr-first'}>
        <td colSpan={11} className={'td-no-padding'}>
          <table className={'table-no-padding'}>
            <tbody>
                
                <tr className={isOpen ? 'openTrRow': 'hiddenTrRow'}>
                  <td className={'td-no-padding ' + isOpen ? 'openTdRow': 'hiddenTdRow'} colSpan={11} >
                    <div className={isOpen ? 'openRow': 'hiddenRow'}>
                      <div className={'carRow'}>
                        <div className="rowContainer" style={{ backgroundColor: 'transparent', width: '100%' }}>
                          <div className="rowOuter" style={{ backgroundColor: 'transparent', width: '26%',  }}>
                            {
                            !this.state.isLoading ? 
                            (
                            <VerticalTimeline layout={ '1-column-left' } className="custom-timeline">
                              <VerticalTimelineElement
                                iconStyle={{ background: '#2176A8', color: '#fff' }} 
                                icon={<img src="assets/images/load-pickup.png" alt="-" ></img>}
                              >
                                { this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? 
                                  <strong onClick={() => this.props.history.push('/subscription') } className="link" >{originName === originAddress.replace(/,/g, '') ? originTitle : originName}</strong> :
                                  <Link to={this.state.backgroundCheckStatus === BackgroundCheckStatus.INACTIVE_SUBSCRIPTION ? '/subscription' : '/settings'} className="link" style={{ color: 'black', fontWeight: '700' }}>{originName}</Link>
                                }
                                { originAddress && <span><br/><span onClick={() => googleMapSearch(originAddress)} className="link">{originAddress}</span></span> }
                                { originPhone && <span><br/><span onClick={() => phoneCall(originPhone)} className="link">{'Tel. ' + originPhone}</span></span> }
                                { originEmail && <span><br/><span onClick={() => mailTo(originEmail, originName)} className="link">{'Email: ' + originEmail}</span></span> }
                              </VerticalTimelineElement>
                              <VerticalTimelineElement
                                iconStyle={{ background: '#48b686', color: '#fff' }} 
                                icon={<img src="assets/images/load-delivery.png" alt="-" ></img>}
                              >
                                { this.state.backgroundCheckStatus === BackgroundCheckStatus.APPROVED ? 
                                  <strong onClick={() => googleSearch(destinationName)} className="link" >{destinationName === destinationAddress.replace(/,/g, '') ? destinationTitle : destinationName}</strong> :
                                  <Link to={this.state.backgroundCheckStatus === BackgroundCheckStatus.INACTIVE_SUBSCRIPTION ? '/subscription' : '/settings'} className="link" style={{ color: 'black', fontWeight: '700' }}>{destinationName}</Link>
                                }
                                { destinationAddress && <span><br/><span onClick={() => googleMapSearch(destinationAddress)} className="link">{destinationAddress}</span></span> }
                                { destinationPhone && <span><br/><span onClick={() => phoneCall(destinationPhone)} className="link">{'Tel. ' + destinationPhone}</span></span> }
                                { destinationEmail && <span><br/><span onClick={() => mailTo(destinationEmail, destinationName)} className="link">{'Email: ' + destinationEmail}</span></span> }
                              </VerticalTimelineElement>
                              <VerticalTimelineElement
                                iconStyle={{ background: 'grey', color: '#fff' }} 
                                icon={<img src="assets/images/load-total.png" alt="-" ></img>}
                              >
                                <span onClick={() => googleMapRoute(originAddress, destinationAddress)} className="link"><strong>Route Distance</strong>: {item.miles} miles</span>
                              </VerticalTimelineElement>
                            </VerticalTimeline>) : (
                            <div style={{ width: '100%', height: '100%', fontSize: '6em',display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'lightslategray' }}>
                              <i className="mdi mdi-timelapse"/>
                            </div>)
                            }
                          </div>
                           
                          
                            <div className="rowInner" style={{ marginTop: '24px' }}>
                            { brokerPresent ?
                              <div>
                              <ul>
                                <li className="rowSubtitle">BROKER</li>
                                { brokerCompany && <li onClick={() => googleSearch(brokerCompany)}><strong>{brokerCompany}</strong></li> }
                                { brokerName && <li onClick={() => googleSearch(brokerName)}><strong>{brokerName}</strong></li> }
                                <li onClick={() => googleMapSearch(brokerAddress)}>{brokerAddress}</li>
                                <li onClick={() => phoneCall(brokerPhone)}>{brokerPhone ? 'Tel. ' + brokerPhone : ''}</li>
                                <li onClick={() => mailTo(brokerEmail, brokerName)}>{brokerEmail ? 'Email: ' + brokerEmail : ''}</li>
                              </ul>
                              <br/>
                              </div>
                              : '' }
                              <ul>
                                <li className="rowSubtitle">LOAD</li>
                                <li style={{ marginTop: '2px', marginBottom: '2px' }}><strong className={item.priority === "rush" ? "urgent-label" : "normal-label"}>{item.priority ? <i className="mdi mdi-flash"/> : "#"}{item.load_id || '-'}</strong></li>
                                <li>Published on {formatShortDate(item.date_add) || '-'}</li>
                                <li>Order for {this.state.carData.length} car{this.state.carData.length >= 2 ? 's' : ''}</li>
                              </ul>
                            </div>
                          

                          <div className="rowLastEnd">
                            <AvForm id={"form@" + item.id} onSubmit={this.submit}>
                              <div className="opportunities-form" style={{flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', height: '100%', paddingTop: 'auto', marginTop: 'auto' }}>
                                <div className="opportunity-form-row3" style={{ flexDirection: 'column', alignItems: 'normal', margin: '0px' }}>
                                  <div className="opportunity-form-row2">
                                    <AvField 
                                      id={"pick_up_date@" + item.id} 
                                      name="pick_up_date" 
                                      label="Pickup Date" 
                                      type="date" 
                                      value={ pick_up_date } 
                                      validate={{required: {value: true}}} 
                                      onChange={(e) => this.handleChange(e)}
                                      disabled={this.state.isLoading || !this.state.allowSubmit || this.state.cancelOffer}
                                    />
                                    <AvField 
                                      id={"delivery_date@" + item.id} 
                                      name="delivery_date" 
                                      label="Delivery Date" 
                                      type="date" 
                                      value={ delivery_date } 
                                      validate={{required: {value: true}}} 
                                      onChange={(e) => this.handleChange(e)}
                                      disabled={this.state.isLoading || !this.state.allowSubmit || this.state.cancelOffer}
                                    />
                                  </div>
                                  <div className="opportunity-form-row2" style={{ width: '100%' }}>
                                    <AvField 
                                      id={"price@" + item.id} 
                                      type="number" 
                                      name="hauling" 
                                      placeholder="0$" 
                                      value={this.state.hauling} 
                                      validate={{required: {value: true}, min: {value: 1}}} 
                                      onChange={(e) => this.handleChange(e)} 
                                      label="Price" 
                                      disabled={this.state.isLoading || !this.state.allowSubmit || this.state.cancelOffer}
                                      />
                                  </div>
                                  <div className="opportunity-form-row4" style={{ justifyContent: 'flex-start' }}>
                                    <AvField 
                                      id={"terms@" + item.id} 
                                      name="termsRequired" 
                                      value={!this.state.termsRequired} 
                                      type="checkbox" 
                                      // selected={this.state.termsRequired === "false" || this.state.termsRequired === false ? false : true} 
                                      style={{ position: 'relative', top: '-10px', width:'10px' }} 
                                      onChange={(e) => this.handleChange(e)}
                                      disabled={this.state.isLoading || !this.state.allowSubmit || this.state.cancelOffer}
                                    />
                                    <label htmlFor={"terms@" + item.id} style={{ whiteSpace: 'pre-wrap', paddingLeft: '10px' }}>
                                      I have read and agreed with provided<br/>
                                      Terms and Conditions
                                    </label>
                                  </div>             
                                </div>

                                <div className="opportunity-form-row4" style={{ flexDirection: 'column', marginLeft: '20px', marginRight: '20px' }}>
                                  <div className="opportunity-form-column" style={{ alignItems: 'center', height: '100%', width: '100%' }}>
                                    <span className="car_detail_box2" style={{ width: '100%', height: '180px', marginLeft: '0px', marginRight: '0px' }}>
                                      <span className="load_details_header3" style={{ width: '100%', paddingTop: '44px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '30px' }} >
                                        <span style={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '32px', height: '100%' }}>
                                          ${offerPrice}
                                        </span>
                                        <div style={{ paddingTop: '0px', width: 'auto' }}>
                                          ${offerPricePerMiles}/mi
                                        </div>
                                        <div style={{ padding: '10px', width: 'auto' }}>
                                          <span className={paymentTerm ? ('paymentTerm_' + paymentTerm.replace(/\s/g, '_')) : ''}>
                                            {paymentTerm}
                                          </span>
                                        </div>
                                        { revenue !== 0 || this.state.cancelOffer ?
                                        (<div style={{ paddingTop: '0px', width: 'auto', color: revenue > 0 ? "green" : ( revenue < 0 ? "red" : "grey") }}>
                                          { revenue >= 0 ? "+$" + revenue + "" : "-$" + (revenue * -1) + "" }
                                        </div>) : null
                                        }
                                      </span>
                                    </span>

                                    { this.state.needConfirmation ? (
                                      <span className="car_detail_box2" style={{ width: '200px', marginTop: '10px' }}>
                                      <Button
                                        id={"accept@" + item.id} 
                                        type="submit"
                                        disabled={ this.state.termsRequired || this.state.offerAccepted || this.state.offerDeclined }
                                        className={ "btn btn-primary w-md custom-btn" } 
                                        style={{ flex: 1, height: '36px', margin: '0px', minWidth: "0px", marginRight: '5px', backgroundColor: '#419c5f' }} 
                                        onClick={ this.onAccept }
                                      >
                                        { "Accept" }
                                      </Button>
                                      <Button
                                        id={"decline@" + item.id} 
                                        type="submit"
                                        disabled={ this.state.termsRequired || this.state.offerAccepted || this.state.offerDeclined }
                                        className={ "btn btn-primary w-md custom-btn" } 
                                        style={{ flex: 1, height: '36px', margin: '0px', minWidth: "0px", marginLeft: '5px', backgroundColor: '#dc3545' }}
                                        onClick={ this.onDecline }
                                      >
                                        { "Decline" }
                                      </Button>
                                    </span>
                                    ) : (
                                      <span className="car_detail_box2" style={{ width: '200px', marginTop: '10px' }}>
                                      <Button
                                        id={"submit@" + item.id} 
                                        type="submit"
                                        disabled={ this.state.termsRequired || this.state.offerAccepted || this.state.offerDeclined || !this.state.allowSubmit }
                                        className={ this.state.offerAccepted ? "btn btn-primary w-md custom-btn accepted-button" : ( this.state.offerDeclined ? "btn btn-primary w-md custom-btn declined-button" : "btn btn-primary w-md custom-btn")} 
                                        style={{ flex: 1, height: '36px', margin: '0px' }} 
                                      >
                                        { this.state.submitOperation }
                                      </Button>
                                    </span>
                                    ) }
                                  </div>
                                </div>
                              </div>
                            </AvForm>
                          </div>

                        </div>
                      </div>
                      
                      <span className="car_detail_box">
                        {this.state.carData.map((car) => {
                          return (
                            <span className="load_details_header" onClick={() => googleSearch((car.year || '') + " " + (car.car_maker_name || '') + " " + (car.car_model_name || '') + " " + (car.car_type ? car.car_type : ''))}>
                              <strong>{(car.year || '')} {(car.car_maker_name || '')} {(car.car_model_name || '')}</strong> <span className="rowTitle">{(car.car_type ? ('Type: ' + car.car_type) : '')}</span> <span className="rowTitle">{(car.vin ? ('VIN: ' + car.vin) : '')}</span>
                            </span>
                          )
                        })}
                      </span>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

class Loadboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loads: [],
      page: 1,
      initialPage: 0,
      limit: PAGE_SIZE,
      total: 0,
      status: "active",
      popupOpen: false,
      delayTimer: "",
      commentMode: null,
      commentText: "",
      priority: null,
      transporters: [],
      drivers: [],
      pickupLocations: [],
      deliveryLocations: [],
      defaultSelectValue: { label: SELECT_DRIVER_HINT, value: "" },
      pickupLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''},
      deliveryLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''},
      isLoading: false,
      files: [],
      subscriptionStatus: '',
      pickupDistance: DEFAULT_PICKUP_DISTANCE,
      deliveryDistance: DEFAULT_DELIVERY_DISTANCE,
      pickup_driver_id: null,
      pickup_place_id: null,
      delivery_driver_id: null,
      delivery_place_id: null,
      backgroundCheckStatus: null,
    };
    this.setLoading = this.setLoading.bind(this);
    this.mapRef = React.createRef();
  }

  async getLoadCards(load) {
    const data = await LoadsAPI.GetLoadsCars(load.id)
    const resp = data.data.data
    let carDataText = []
    resp.forEach(el => {
      carDataText.push(el);
    })
    return carDataText;
  }

  getLoads = async () => {
    const { page } = this.state;
    const data = await this.getLoadsWithFilter(page);
    this.setState({ loads: data.data.data, total: data.data.data_total_count });
  };

  getLoadsWithFilter = async page => {
    const { limit, status, priority, pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, deliveryDistance, pickupDistance } = this.state;

    const data = await OpportunitiesAPI.GetOffersData({
      page,
      limit,
      status,
      ...(priority ? { priority: "rush" } : {}),
      ...(pickup_driver_id ? { pickup_driver_id } : {}),
      ...(pickup_place_id ? { pickup_place_id } : {}),
      ...(delivery_driver_id ? { pickup_driver_id } : {}),
      ...(delivery_place_id ? { pickup_place_id } : {}),
      ...(pickup_driver_id || pickup_place_id || delivery_driver_id || delivery_place_id ? { 
        pickup_distance: pickupDistance, delivery_distance: deliveryDistance 
      } : {}),
    });

    await Promise.all(data.data.data.map(async (load) => {
      load.carData = await this.getLoadCards(load);
      load.offerData = await OpportunitiesAPI.getOffer(load.id);

      if (load.offerData.data && load.offerData.data.length > 0) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        
        load.offerData = load.offerData.data.find((offer) => (offer.author_id === userInfo.id || offer.dispatcher_id === userInfo.id) && offer.confirmed >= 0);

        // TODO if declined, count how many total declines, if more than 3, don't show the load, if less - do not return the offer
        // load.offerData = null;
      } else {
        load.offerData = null;
      }
    }));

    // Filter out data with offers where confirmed < 0
    const filteredData = data.data.data.filter((load) => {
      if (load.offerData === null || (load.offerData && load.offerData.confirmed >= 0)) {
        return true;
      }
      // TODO filter out the rejected offers ???
      // data.data.data_total_count = data.data.data_total_count - 1;
      // return false;
      return true;
    });
    data.data.data = filteredData;

    return data;
  };

  async componentDidMount() {
    const backgroundCheckStatus = await getBackgroundCheckStatus();
    this.setState({backgroundCheckStatus});

    const subscriptionData = await SubscriptionAPI.GetSubscribeData();
    if (subscriptionData.data.status === 500 && subscriptionData.data.data) {
      this.setState({
        subscriptionStatus: subscriptionData.data.data.status,
      });
    }

    this.getLoads();
    const data = await TransportersAPI.GetTransportersStatWithLoad();
    const { data: allDrivers } = await TransportersAPI.GetMyTransportersData();
    const {
      data: { files }
    } = await DocumentsApi.list();
    const transportersStatusFilter = data.data.data.filter(
      item => item.status !== "invited"
    );
    const transporters = transportersStatusFilter.map(item => {
      return { value: item.id, label: `${item.name}`, id: item.id };
    });
    const drivers = allDrivers.data.map(el => ({
      label: `${el.first_name} ${el.last_name}`,
      value: el.id
    }));
    // transporters.unshift({ label: SELECT_DRIVER_HINT, value: "" });

    const options = [
      {
        // label: 'Clear',
        options: [
          { label: SELECT_DRIVER_HINT, value: "", isDisabled: false }
        ],
      },
      {
        label: 'Drivers',
        options: [
          ...transporters
        ],
      }
    ];

    this.setState({ transporters, drivers, files, pickupLocations: options, deliveryLocations: options });
  }

  handlePageChange = async page => {
    const data = await this.getLoadsWithFilter(page.selected + 1);
    this.setState({
      page: page.selected + 1,
      loads: data.data.data,
      initialPage: page.selected
    });
  };

  openPopup = id => {
    this.setState({ popupOpen: true, popupItemId: id });
  };

  handleRush = async e => {
    const {
      target: { checked }
    } = e;
    const { status, pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, deliveryDistance, pickupDistance } = this.state;
    const pageLimit = pickup_driver_id > 0 || (pickup_place_id && pickup_place_id.trim() !== '') 
      || delivery_driver_id > 0 || (delivery_place_id && delivery_place_id.trim() !== '') ? 100 : PAGE_SIZE;
    this.setState({priority: checked ? "rush" : null, isLoading: true});
    const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, checked ? { priority: "rush" } : null, deliveryDistance, pickupDistance, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: delivery_driver_id }, { place_id: delivery_place_id });
    const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
    this.setState({ limit: pageLimit, initialPage: 0, page: 1, loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData }, () => this.mapRef.current.fetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, checked, pickupDistance, deliveryDistance));
  };

  onPickupLocationChange = async (selectedLocation) => {
    const { status, deliveryDistance, pickupDistance, priority, delivery_driver_id, delivery_place_id } = this.state;
    const isFiltering = selectedLocation && ((selectedLocation.id && !isNaN(selectedLocation.id)) || (selectedLocation.place_id));
    const pageLimit = isFiltering || delivery_driver_id > 0 || (delivery_place_id && delivery_place_id.trim() !== '') ? 100 : PAGE_SIZE;
    if (!isFiltering) {
      this.setState({pickupLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''}, isLoading: true});
      const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, pickupDistance, { id: null }, { place_id: null }, { id: delivery_driver_id }, { place_id: delivery_place_id });
      const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
      this.setState({limit: pageLimit, pickup_driver_id: null, pickup_place_id: null, initialPage: 0, page: 1, loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData }, () => this.mapRef.current.fetchData(null, delivery_driver_id, null, delivery_place_id, priority === "rush", pickupDistance, deliveryDistance));
    } else {
      this.setState({pickupLocationSelectedValue: isFiltering ? selectedLocation : '' , isLoading: true});
      const driver = (selectedLocation && selectedLocation.id && !isNaN(selectedLocation.id)) ? selectedLocation : { id: null };
      const place = (selectedLocation && selectedLocation.place_id && selectedLocation.place_id.trim() !== "") ? selectedLocation : { place_id: null };
      const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, pickupDistance, driver, place, { id: delivery_driver_id }, { place_id: delivery_place_id });
      const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
      this.setState({limit: pageLimit, pickup_driver_id: driver.id || null, pickup_place_id: place.place_id || null, initialPage: 0, page: 0, loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData }, () => this.mapRef.current.fetchData(driver.id || null, delivery_driver_id, place.place_id || null, delivery_place_id, priority === "rush", pickupDistance, deliveryDistance));
    }
  }

  onDeliveryLocationChange = async (selectedLocation) => {
    const { status, deliveryDistance, pickupDistance, priority, pickup_driver_id, pickup_place_id } = this.state;
    const isFiltering = selectedLocation && ((selectedLocation.id && !isNaN(selectedLocation.id)) || (selectedLocation.place_id));
    const pageLimit = isFiltering || pickup_driver_id > 0 || (pickup_place_id && pickup_place_id.trim() !== '') ? 100 : PAGE_SIZE;
    if (!isFiltering) {
      this.setState({deliveryLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''}, isLoading: true});
      const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, pickupDistance, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: null }, { place_id: null });
      const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
      this.setState({limit: pageLimit, delivery_driver_id: null, delivery_place_id: null, initialPage: 0, page: 1, loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData }, () => this.mapRef.current.fetchData(pickup_driver_id, null, pickup_place_id, null, priority === "rush", pickupDistance, deliveryDistance));
    } else {
      this.setState({deliveryLocationSelectedValue: isFiltering ? selectedLocation : '' , isLoading: true});
      const driver = (selectedLocation && selectedLocation.id && !isNaN(selectedLocation.id)) ? selectedLocation : { id: null };
      const place = (selectedLocation && selectedLocation.place_id && selectedLocation.place_id.trim() !== "") ? selectedLocation : { place_id: null };
      const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, pickupDistance, { id: pickup_driver_id }, { place_id: pickup_place_id }, driver, place);
      const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
      this.setState({limit: pageLimit, delivery_driver_id: driver.id || null, delivery_place_id: place.place_id || null, initialPage: 0, page: 0, loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData }, () => this.mapRef.current.fetchData(pickup_driver_id, driver.id || null, pickup_place_id, place.place_id || null, priority === "rush", pickupDistance, deliveryDistance));
    }
  }

  onPickupDistanceChange = async (e) => {
    const { status, pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, pickupDistance, deliveryDistance, priority } = this.state;
    const pageLimit = pickup_driver_id > 0 || (pickup_place_id && pickup_place_id.trim() !== '')
      || delivery_driver_id > 0 || (delivery_place_id && delivery_place_id.trim() !== '') ? 100 : PAGE_SIZE;
    let distance = e.target.value;
    distance = parseInt(distance, 10);
    distance = distance - 1 === pickupDistance ? 
      pickupDistance + DEFAULT_DISTANCE_STEP : 
      distance + 1 === pickupDistance ? 
      pickupDistance - DEFAULT_DISTANCE_STEP : distance;
    
    if (pickup_driver_id > 0 || (pickup_place_id && pickup_place_id.trim() !== '')) {
      this.setState({pickupDistance: distance, isLoading: true});
      if (distance === null || distance === '' || isNaN(distance)) {
        const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, null, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: delivery_driver_id }, { place_id: delivery_place_id });
        const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
        this.setState({ loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData, limit: pageLimit, initialPage: 0, page: 1}, () => this.mapRef.current.fetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, priority === "rush", null, deliveryDistance));
      } else {
        const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, distance, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: delivery_driver_id }, { place_id: delivery_place_id });
        const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
        this.setState({ loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData, limit: pageLimit, initialPage: 0 }, () => this.mapRef.current.fetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, priority === "rush", distance, deliveryDistance));
      }
    }
  }

  onDeliveryDistanceChange = async (e) => {
    const { status, pickup_driver_id, pickup_place_id, delivery_driver_id, delivery_place_id, pickupDistance, deliveryDistance, priority } = this.state;
    const pageLimit = pickup_driver_id > 0 || (pickup_place_id && pickup_place_id.trim() !== '')
      || delivery_driver_id > 0 || (delivery_place_id && delivery_place_id.trim() !== '') ? 100 : PAGE_SIZE;
    let distance = e.target.value;
    distance = parseInt(distance, 10);
    distance = distance - 1 === deliveryDistance ? 
      deliveryDistance + DEFAULT_DISTANCE_STEP : 
      distance + 1 === deliveryDistance ? 
      deliveryDistance - DEFAULT_DISTANCE_STEP : distance;

    if (delivery_driver_id > 0 || (delivery_place_id && delivery_place_id.trim() !== '')) {
      this.setState({deliveryDistance: distance, isLoading: true});
      if (distance === null || distance === '' || isNaN(distance)) {
        const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, null, pickupDistance, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: delivery_driver_id }, { place_id: delivery_place_id });
        const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
        this.setState({ loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData, limit: pageLimit, initialPage: 0, page: 1 }, () => this.mapRef.current.fetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, priority === "rush", pickupDistance, null));
      } else {
        const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, distance, pickupDistance, { id: pickup_driver_id }, { place_id: pickup_place_id }, { id: delivery_driver_id }, { place_id: delivery_place_id });
        const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
        this.setState({ loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData, limit: pageLimit, initialPage: 0, page: 1 }, () => this.mapRef.current.fetchData(pickup_driver_id, delivery_driver_id, pickup_place_id, delivery_place_id, priority === "rush", pickupDistance, distance));
      }
    }
  }

  handleLocation = async selectedLocation => {
    // const { status, priority, deliveryDistance, pickupDistance } = this.state;
    // const pageLimit = selectedDriver.id && selectedDriver.id > 0 ? 100 : PAGE_SIZE;
    // const opportunitiesResponse = await this.getOpportunitiesData(pageLimit, status, priority, deliveryDistance, pickupDistance, selectedDriver);
    // const opportunitiesData = opportunitiesResponse && opportunitiesResponse.data ? opportunitiesResponse.data : [];
    // this.setState({ driver_id: selectedDriver.id || null, limit: pageLimit, initialPage: 0, page: 1, loads: opportunitiesData.data, total: opportunitiesData.data_total_count, isLoading: !opportunitiesData }, () => this.mapRef.current.fetchData(selectedDriver.id, priority === "rush"));

    this.onPickupLocationChange(selectedLocation);
  };


  changePickupFilterCriteria = input => {
    if (input & input.trim() === "") {
      return;
    }

    OpportunitiesAPI.getPlaces(input).then((responseData) => {
      const places = responseData.data.map(item => ({ label: item.description, value: item.description, place_id: item.place_id }));

      const options = [
        {
          // label: 'Clear',
          options: [
            { label: SELECT_DRIVER_HINT, value: "", isDisabled: false }
          ],
        },
        {
          label: 'Drivers',
          options: [
            ...this.state.transporters
          ],
        },
        {
          label: 'Places',
          options: [
            ...places 
          ],
        },
      ];
      
      this.setState({pickupLocations: options});
    });
  };

  changeDeliveryFilterCriteria = input => {
    if (input & input.trim() === "") {
      return;
    }

    OpportunitiesAPI.getPlaces(input).then((responseData) => {
      const places = responseData.data.map(item => ({ label: item.description, value: item.description, place_id: item.place_id }));

      const options = [
        {
          // label: 'Clear',
          options: [
            { label: SELECT_DRIVER_HINT, value: "", isDisabled: false }
          ],
        },
        {
          label: 'Drivers',
          options: [
            ...this.state.transporters
          ],
        },
        {
          label: 'Places',
          options: [
            ...places 
          ],
        },
      ];
      
      this.setState({deliveryLocations: options});
    });
  };

  // TODO selectedDriver and selectedPlace has to be replaced with specific pickup and delivery locations
  getOpportunitiesData = async (limit, status, priority, deliveryDistance, pickupDistance, selectedPickupDriver, selectedPickupPlace, selectedDeliveryDriver, selectedDeliveryPlace) => {
    const data = await OpportunitiesAPI.GetOffersData({
      page: 1,
      limit,
      status,
      ...(priority ? { priority: "rush" } : {}),
      ...(selectedPickupDriver.id ? { pickup_driver_id: selectedPickupDriver.id } : {}),
      ...(selectedPickupPlace.place_id ? { pickup_place_id: selectedPickupPlace.place_id } : {}),
      ...(selectedDeliveryDriver.id ? { delivery_driver_id: selectedDeliveryDriver.id } : {}),
      ...(selectedDeliveryPlace.place_id ? { delivery_place_id: selectedDeliveryPlace.place_id } : {}),
      ...(selectedPickupDriver.id || selectedPickupPlace.place_id || selectedDeliveryDriver.id || selectedDeliveryPlace.place_id ? { pickup_distance: pickupDistance, delivery_distance: deliveryDistance } : {})
    });

    await Promise.all(data.data.data.map(async (load) => {
      load.carData = await this.getLoadCards(load);
      load.offerData = await OpportunitiesAPI.getOffer(load.id);

      if (load.offerData.data && load.offerData.data.length > 0) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        load.offerData = load.offerData.data.find((offer) => (offer.author_id === userInfo.id || offer.dispatcher_id === userInfo.id) && offer.confirmed >= 0);

        // TODO if declined, count how many total declines, if more than 3, don't show the load, if less - do not return the offer
        // load.offerData = null;

      } else {
        load.offerData = null;
      }
    }));

    return data;
  }

  setLoading = value => {
    this.setState({ isLoading: value });
  };

  render() {
    const {
      loads,
      limit,
      total,
      popupOpen,
      pickupLocations,
      deliveryLocations,
      drivers,
      defaultSelectValue,
      priority,
      pickup_driver_id,
      pickup_place_id,
      delivery_driver_id,
      delivery_place_id,
      subscriptionStatus,
      pickupDistance,
      deliveryDistance
    } = this.state;

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <SubscriptionContext.Provider value={subscriptionStatus}>
              <CustomSideBar page={"loadboard"} />
            </SubscriptionContext.Provider>
            <CustomTopBar />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">
                      Loadboard
                    </div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Loadboard</li>
                    </ol>
                  </div>
                </div>

                <div className="dashboard-content">
                  <div className="people-table">
                    <div className="people-table-header">
                      <ul className="load-table-header header-first">
                        <li>
                          <div className="people-table-header-title"
                           style={{ height: "36px", lineHeight: "36px", alignContent: "center"}}
                           >
                            
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <span className="custom-form-control-wrap">
                                  <div className="input-group" 
                                  style={{width: "530px"}}
                                  >
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        Pick Up location
                                      </span>
                                      <span className="custom-form-control-wrap">
                                        <Select
                                          className="custom-form-control-select-locations"
                                          styles={{ width: "330px", borderRadius: "0px" }}
                                          defaultValue={defaultSelectValue}
                                          onChange={this.onPickupLocationChange}
                                          onInputChange={this.changePickupFilterCriteria}
                                          options={pickupLocations}
                                          placeholder={"type transporter's name, city"}
                                          value={this.state.pickupLocationSelectedValue}
                                          onFocus={(e) => {
                                            if (this.state.pickupLocationSelectedValue.value === '') {
                                              this.setState({pickupLocationSelectedValue: {label: '', value: ''}});
                                            }
                                          }}
                                          onBlur={(e) => {
                                            if (this.state.pickupLocationSelectedValue.value === '') {
                                              this.setState({pickupLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''}});
                                            } else {
                                              this.onPickupLocationChange(this.state.pickupLocationSelectedValue);
                                            }
                                          }}
                                        />
                                      </span>
                                      <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        maxLength="10"
                                        onChange={this.onPickupDistanceChange}
                                        value={pickupDistance}
                                        style={{width: "80px", borderRadius: "0px"}}
                                      />
                                    </div>
                                    <span className='hideControl'/>
                                  </div>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li style={{ height: '38px', display: 'flex', alignItems: 'center', verticalAlign: 'center'}}>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <strong onClick={() =>
                                  this.setState({
                                    pickupLocationSelectedValue: this.state.deliveryLocationSelectedValue, 
                                    deliveryLocationSelectedValue: this.state.pickupLocationSelectedValue,
                                    pickup_driver_id: this.state.delivery_driver_id,
                                    pickup_place_id: this.state.delivery_place_id,
                                    delivery_driver_id: this.state.pickup_driver_id,
                                    delivery_place_id: this.state.pickup_place_id,
                                    pickupDistance: this.state.deliveryDistance,
                                    deliveryDistance: this.state.pickupDistance,
                                    isLoading: true
                                  }, () => { 
                                    this.onPickupLocationChange(this.state.pickupLocationSelectedValue).then(() => {
                                      this.onDeliveryLocationChange(this.state.deliveryLocationSelectedValue);
                                    }).finally(() => this.setState({isLoading: false}));
                                  })
                                } className="link_direction">
                                  <i className="mdi mdi-arrow-left-right-bold-outline" style={{ fontSize: '24px' }}/>
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <span className="custom-form-control-wrap">
                                  <div className="input-group" 
                                  style={{width: "530px"}}
                                  >
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        Delivery location
                                      </span>
                                      <span className="custom-form-control-wrap">
                                        <Select
                                          className="custom-form-control-select-locations"
                                          styles={{ width: "330px", borderRadius: "0px" }}
                                          defaultValue={defaultSelectValue}
                                          onChange={this.onDeliveryLocationChange}
                                          onInputChange={this.changeDeliveryFilterCriteria}
                                          options={deliveryLocations}
                                          placeholder={"type transporter's name, city"}
                                          value={this.state.deliveryLocationSelectedValue}
                                          onFocus={(e) => {
                                            if (this.state.deliveryLocationSelectedValue.value === '') {
                                              this.setState({deliveryLocationSelectedValue: {label: '', value: ''}});
                                            }
                                          }}
                                          onBlur={(e) => {
                                            if (this.state.deliveryLocationSelectedValue.value === '') {
                                              this.setState({deliveryLocationSelectedValue: {label: SELECT_DRIVER_HINT, value: ''}});
                                            } else {
                                              this.onDeliveryLocationChange(this.state.deliveryLocationSelectedValue);
                                            }
                                          }}
                                        />
                                      </span>
                                      <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        maxLength="10"
                                        onChange={this.onDeliveryDistanceChange}
                                        value={deliveryDistance}
                                        style={{width: "80px", borderRadius: "0px"}}
                                      />
                                    </div>
                                    <span className='hideControl'/>
                                  </div>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <input
                                  className={"filter_checkbox"}
                                  defaultChecked={priority === "rush"}
                                  ref="rush"
                                  type={"checkbox"}
                                  onChange={this.handleRush}
                                />{" "}
                                rush only
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="people-table-header-search">{total} search results</div>
                    <table>
                      <tbody>
                        {
                          sortByPriority(loads).map((item, i) => {
                            return (
                              <RowItem 
                                key={uuidv4()} 
                                item={item} 
                                carData={item.carData || []}
                                offerData={item.offerData || {}}
                                isLoading={this.state.isLoading}
                                drivers={drivers} 
                                openPopup={this.openPopup} 
                                getLoads={this.getLoads}
                                setLoading={this.setLoading}
                                backgroundCheckStatus={this.state.backgroundCheckStatus}
                              />
                            )
                          })
                      }
                      </tbody>
                    </table>

                    { (
                      this.state.pickupLocationSelectedValue.value !== '' && this.state.pickupLocationSelectedValue.value !== null && this.state.pickupLocationSelectedValue.value !== undefined
                    ) || (
                      this.state.deliveryLocationSelectedValue.value !== '' && this.state.deliveryLocationSelectedValue.value !== null && this.state.deliveryLocationSelectedValue.value !== undefined
                    ) ? (null) : (
                    <ReactPaginate
                      forceSelected={this.state.initialPage}
                      forcePage={this.state.initialPage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      marginPagesDisplayed={2}
                      containerClassName={"pagination custom-pagination"}
                      subContainerClassName={"pages pagination "}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item disabled"}
                      breakLinkClassName={"page-link disabled"}
                      onPageChange={this.handlePageChange}
                      pageCount={Math.ceil(total / limit)}
                    />
                  ) }
                  </div>

                  <div className="loads-map">
                    <div className="loads-map-header">
                      <div className="loads-map-title">Map</div>
                    </div>
                    <Map internalId={"offerMap"} ref={this.mapRef} pickupDriverId={pickup_driver_id} pickupPlaceId={pickup_place_id} deliveryDriverId={delivery_driver_id} deliveryPlaceId={delivery_place_id} rushOnly={priority === "rush"} pickupDistance={pickupDistance} deliveryDistance={deliveryDistance} showLoads={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popupOpen ? (
            <div
              className="modal fade bs-example-modal-lg custom-modal show "
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              style={{
                paddingRight: "15px",
                display: "block",
                background: "rgba(0,0,0,0.6)"
              }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      Delete/Edit load
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                      This will remove driver from the load or completely delete
                      the load if status is NEW. <br />
                      Proceed?
                    </p>
                    <Button
                      className="btn btn-primary w-md custom-btn"
                      onClick={this.deleteLoad}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn w-md"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </main>
      </AUX>
    );
  }
}

function calculateOfferValues (load) {
  let cancelOffer = false;
  let offerAccepted = false;
  let pick_up_date = load.pick_up_date ? load.pick_up_date.split(' ')[0] : formatInputDate();
  let delivery_date = load.delivery_date ? load.delivery_date.split(' ')[0] : formatInputDate();
  let hauling = load.hauling ? Math.floor(parseFloat(load.hauling)) : 0;
  let offered_hauling = 0;
  let revenue = 0;
  let needConfirmation = false;
  let offerDeclined = false;
  let offerCreated = true;

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  if (load.offerData && load.offerData.id) {
    offerAccepted = load.offerData.confirmed === 1;
    cancelOffer = load.offerData.author_id === userInfo.id && load.offerData.confirmed === 0;

    needConfirmation = load.offerData.dispatcher_id === userInfo.id && load.offerData.confirmed === 0 && load.offerData.planned_driver_id;
    offerDeclined = load.offerData.confirmed === -1;

    pick_up_date = load.offerData.pick_up_date ? load.offerData.pick_up_date.split(' ')[0] : pick_up_date;
    delivery_date = load.offerData.delivery_date ? load.offerData.delivery_date.split(' ')[0] : delivery_date;
    offered_hauling = load.offerData.hauling ? Math.floor(parseFloat(load.offerData.hauling)) : 0;
    revenue = offered_hauling - hauling;
    hauling = offered_hauling;
    offerCreated = false;
  }

  const termsRequired = needConfirmation ? false : !(cancelOffer ? true : (offerAccepted ? true : (load.termsRequired === undefined || load.termsRequired === null ? false : (load.termsRequired === "true" || load.termsRequired === true))));
  const allowSubmit = offerDeclined || needConfirmation || offerAccepted ? false : (cancelOffer || offerCreated ? true : false);
  const submitOperation = cancelOffer ? "Cancel Offer" : (offerAccepted ? "Offer Accepted" : (offerDeclined ? "Offer Declined" : "Send Offer"));

  return { cancelOffer, pick_up_date, delivery_date, hauling, offered_hauling, revenue, termsRequired, submitOperation, allowSubmit, offerAccepted, needConfirmation, offerDeclined };
}

export default Loadboard;
