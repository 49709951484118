import React, { Component } from 'react';

import './style.css';

class GoogleMap extends Component {
  constructor(props) {
    super(props)
    this.onScriptLoad = this.onScriptLoad.bind(this)
  }

  onScriptLoad() {
    try {
      const map = new window.google.maps.Map(
        document.getElementById(this.props.id),
        this.props.options)
      this.props.onMapLoad(map)
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    const _this = this
    if (!window.google) {
      let s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = `https://maps.google.com/maps/api/js?key=${this.props.apiKey}&libraries=${this.props.libraries}&language=${this.props.language}&region=${this.props.region}`
      let x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
      s.addEventListener('load', e => {
        // _this.onScriptLoad()
      })

      s.onload = function() {
        let s1 = document.createElement('script')
        s1.type = 'text/javascript'
        s1.src = `assets/js/markerAnimate.js`
        let x1 = document.getElementsByTagName('script')[1]
        x1.parentNode.insertBefore(s1, x1)

        s1.onload = function() {
          let s2 = document.createElement('script')
          s2.type = 'text/javascript'
          s2.src = `assets/js/SlidingMarker.min.js`
          let x2 = document.getElementsByTagName('script')[2]
          x2.parentNode.insertBefore(s2, x2)

          s2.onload = function() {
            let aa = document.createElement('script')
            aa.text = 'SlidingMarker.initializeGlobally()'
            let bb = document.getElementsByTagName('script')[3]
            bb.parentNode.insertBefore(aa, bb)

            let s3 = document.createElement('script')
            s3.type = 'text/javascript'
            s3.src = `assets/js/markerwithlabel.terikon.js`
            let x3 = document.getElementsByTagName('script')[4]
            x3.parentNode.insertBefore(s3, x3)

            _this.onScriptLoad()
          }
        }
      }
    } else {
      this.onScriptLoad()
    }
  }

  render() {
    return (
      <div>
        <input style={{display: this.props.dataLength ? 'block' : 'none'}} key={this.props.dataLength} id="pac-input" className="controls" type="text" placeholder="Search Box"/>
        <div className="map" id={this.props.id} />
      </div>
    );
  }
}

export default GoogleMap
