export const months = {
    '01': 'JAN',
    '02': 'FEB',
    '03': 'MAR',
    '04': 'APR',
    '05': 'MAY',
    '06': 'JUN',
    '07': 'JUL',
    '08': 'AUG',
    '09': 'SEP',
    '10': 'OCT',
    '11': 'NOV',
    '12': 'DEC',
  }
  
  export function daysSelected(startDate, endDate) {
    return Math.round((((endDate ? new Date(endDate) : new Date(new Date().toDateString())) - (startDate ? new Date(startDate) : new Date(new Date().toDateString()))) / (1000 * 60 * 60 * 24) + 1));
  }

  export function getFormattedDate(fullDate) {
    let dateTimeSplitted, dateSplitted;

    if (fullDate) {
      dateTimeSplitted = (fullDate + '').split(' ')
      dateSplitted = dateTimeSplitted[0].split('-')

      const [month, day] = [(dateSplitted[1] || '-'), (dateSplitted[2] || '-')];
      return dateSplitted[0] !== 'null' || dateSplitted[0] ? [months[month], day].join('-') : '-';
    } else {
      return '-';
    }
  }

  export function formatShortDate(inputDateString) {
    if (!inputDateString) {
      return null;
    }
  
    const inputDate = new Date(inputDateString);
    
    if (isNaN(inputDate.getTime())) {
      // Invalid date string
      return "Invalid Date";
    }
    
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();
    
    return `${month}/${day}/${year}`;
  }

  export function formatInputDate(inputDateString = new Date()) {
    if (!inputDateString) {
      return null;
    }
  
    const inputDate = new Date(inputDateString);
    
    if (isNaN(inputDate.getTime())) {
      // Invalid date string
      return "Invalid Date";
    }
    
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();
    
    return `${year}-${month}-${day}`;
  }