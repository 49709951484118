import React from "react";
import './style.css';

class Spinner extends React.Component {
    render() {
        return (
            <div className="loader">Loading...</div>
        )
    }
}

export default Spinner;
